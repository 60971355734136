export type DropdownValue<V = string> = {
  label: string;
  value: V;
};

export const OrganizationTypes = [
  "SCHOOL",
  "COMPANY",
  "TEST",
  "DEMO",
  "ADMIN",
] as const;

export type OrganizationType = typeof OrganizationTypes[number];
