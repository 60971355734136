import {
  getGlobalSessionStorage,
  useHasAuthenticatedSuccessfully,
  useHasToken,
  GraphiQLView,
} from "@skodel/sk-ui";
// @ts-ignore
import fetch from "isomorphic-fetch";
import React from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";

import LoginModal from "./LoginModal";
import { PageWrapper } from "./PageWrapper";
import AccountsDashboard from "./accounts-dashboard";
import OrganizationsDashboard from "./organizations-dashboard";
import SupportSolutionsDashboard from "./support-solutions-dashboard";
import TagsDashboard from "./tags-dashboard";
import UsersDashboard from "./users-dashboard";

function graphQLFetcher(graphQLParams: any) {
  const token = localStorage.getItem("skodel-auth-token");

  return fetch(getGlobalSessionStorage().getBaseHost() + "/admin/graphql", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-SKODEL-GLOBAL-AUTH": token ? token : "",
    },
    body: JSON.stringify(graphQLParams),
  }).then((response: any) => response.json());
}

const AppRouter = () => {
  const hasAuthenticatedSuccessfully = useHasAuthenticatedSuccessfully();
  const hasToken = useHasToken();

  if (!hasToken) {
    return <Redirect to="/login" />;
  }

  return (
    <PageWrapper>
      <Switch>
        <Route exact path="/tags" component={TagsDashboard} />
        <Route
          exact
          path="/support-solutions"
          component={SupportSolutionsDashboard}
        />
        <Route exact path="/accounts" component={AccountsDashboard} />
        <Route exact path="/organizations" component={OrganizationsDashboard} />
        <Route exact path="/users" component={UsersDashboard} />
        <Route path="/graphiql" component={GraphiQLView} />
        <Redirect exact from="/" to="/tags" />
      </Switch>
      <LoginModal
        isOpen={hasAuthenticatedSuccessfully === false}
        onClose={() => null}
      />
    </PageWrapper>
  );
};

export default AppRouter;
