import { useMutation, useApolloClient } from "@apollo/react-hooks";
import { Text, Flex } from "@skodel/sk-ui";
import {
  TextInput,
  Button,
  OrganizationSelect,
  ModalCloseButton,
  Modal,
  Checkbox,
  FormLabel,
} from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { toast } from "react-toastify";

const CreateUser = loader("./CreateUser.graphql");
const GetOrganizationsByName = loader("./GetOrganizationsByName.graphql");

const promiseOptions: (client: any, inputValue: string) => any = (
  client: any,
  inputValue: string
) => {
  return new Promise((resolve) => {
    client
      .query({
        query: GetOrganizationsByName,
        variables: {
          query: inputValue,
        },
      })
      .then((state: any) => {
        resolve(
          state.data.organizations.edges.map((x: any) => ({
            value: x.node.id,
            label: x.node.name,
          }))
        );
      });
  });
};

const CreateFacilitatorModal = ({ isOpen, onClose, ...otherProps }: any) => {
  const client = useApolloClient();
  const [formState, setFormState]: any = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: null,
    sendSignupEmail: true,
  });

  const [createUser, state] = useMutation(CreateUser);

  const shouldInputBeDisabled =
    formState.firstName.length === 0 ||
    formState.lastName.length === 0 ||
    formState.email.length === 0 ||
    formState.email.indexOf("@") === -1 ||
    formState.organization === null ||
    state.loading;

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Create Wellbeing Facilitator"
    >
      <Modal.Header>
        <Text>Create Wellbeing Facilitator</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormLabel p={2} px={0} htmlFor="firstName">
          First Name
        </FormLabel>
        <TextInput
          id="firstName"
          name="firstName"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              firstName: e.target.value,
            })
          }
          value={formState.firstName}
        />

        <FormLabel p={2} px={0} htmlFor="lastName">
          Last Name
        </FormLabel>
        <TextInput
          id="lastName"
          name="lastName"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              lastName: e.target.value,
            })
          }
          value={formState.lastName}
        />

        <FormLabel p={2} px={0} htmlFor="name">
          Email
        </FormLabel>
        <TextInput
          id="email"
          name="email"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              email: e.target.value,
            })
          }
          value={formState.email}
        />
        <FormLabel p={2} px={0} htmlFor="named">
          Organization
        </FormLabel>

        <OrganizationSelect
          isSearchable
          onChange={(v: any) =>
            setFormState({
              ...formState,
              organization: v,
            })
          }
          value={formState.organization}
          id="named"
          defaultOptions
          name="named"
        />
      </Modal.Body>

      <Flex alignItems="center" justifyContent="flex-start" mt={2}>
        <Checkbox
          mr={2}
          checked={formState.sendSignupEmail}
          onChange={(newValue: any) => {
            setFormState({
              ...formState,
              sendSignupEmail: newValue,
            });
          }}
        />{" "}
        <Text>Send signup email</Text>
      </Flex>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={shouldInputBeDisabled}
          onClick={() => {
            createUser({
              variables: {
                firstName: formState.firstName,
                lastName: formState.lastName,
                email: formState.email,
                organizationId: formState.organization.value,
                sendSignupEmail: formState.sendSignupEmail,
                accountType: "WELLBEING_FACILITATOR",
              },
              refetchQueries: ["GetUsers"],
            })
              .then((response: any) => {
                if (response.data.createUser.success) {
                  toast.success("Successfully created user.");

                  onClose();
                } else {
                  if (
                    [
                      "NoSuchObjectError",
                      "ValidationError",
                      "OperationNotPermitted",
                    ].indexOf(response.data.createUser.__typename) !== -1
                  ) {
                    toast.error(response.data.createUser.message);
                  } else {
                    toast.error(
                      "Could not create user. Please check your internet connection and try again."
                    );
                  }
                }
              })
              .catch((err: any) =>
                toast.error(
                  "Could not create user. Please check your internet connection and try again."
                )
              );
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default CreateFacilitatorModal;
