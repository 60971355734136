// src/LoginForm.js
import {
  Flex,
  Box,
  Button,
  FormLabel,
  TextInput,
  getGlobalSessionStorage,
  loginWithCredentials,
  LogoImage,
} from "@skodel/sk-ui";
import React, { useState } from "react";
import { GoogleLogin } from "react-google-login";
import MicrosoftLogin from "react-microsoft-login";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

const authHandler = (err: any, data: any) => {
  console.log(err, data);
};

const FormGroup = styled(Flex)``;

FormGroup.defaultProps = {
  flexDirection: "column",
  mb: "3",
};

const handleLoginError = (err: any) => {
  if (err.__typename === "InvalidGoogleToken") {
    toast.error("There was an issue signing in with Google. Please try again.");
  } else if (err.__typename === "InvalidEmail") {
    toast.error("Invalid email. Please try again.");
  } else if (err.__typename === "NotConfiguredForGoogle") {
    toast.error(
      "This account is not authorised for Google login. Please contact the person who manages your account."
    );
  } else if (err.__typename === "InvalidPassword") {
    toast.error("Invalid password. Please try again.");
  } else if (err.__typename === "InvalidCredentials") {
    toast.error("Invalid credentials. Please try again.");
  } else if (err.__typename === "EmptyPassword") {
    toast.error("Your password cannot be empty. Please try again.");
  } else if (err.__typename === "AccountHasntOnboarded") {
    toast.error(
      "Could not login. Please check your email and password and try again."
    );
  } else if (err.__typename === "AccountTemporarilyDisabled") {
    toast.error(
      "This account has been temporarily disabled. Please try again later."
    );
  } else if (err.__typename === "AuthenticationRequires2FA") {
    // TODO
  } else {
    toast.error(
      "Could not login. Please check your email and password and try again."
    );
  }
};

const LoginForm = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isLoggingIn = false;

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        loginWithCredentials(email, password);
        return false;
      }}
    >
      <Flex
        flexDirection="column"
        p="4"
        style={{
          minWidth: "300px",
          background: "#FFFFFF",
          borderRadius: "24px",
        }}
      >
        <LogoImage />
        <FormGroup>
          <FormLabel>Email</FormLabel>
          <TextInput
            id="email"
            type="text"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel>Password</FormLabel>
          <TextInput
            id="password"
            type="password"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
        </FormGroup>
        <Button
          variant="primary"
          disabled={isLoggingIn}
          as="input"
          id="submit"
          type="submit"
          value="Login"
          mb="4"
        />
        <Flex
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <GoogleLogin
            clientId="403948996243-fkj59u82gleia56aqbs3p450rdstjiam.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={(response: any) => {
              getGlobalSessionStorage().login(response.tokenId);
            }}
            onFailure={(response: any) => {
              // TODO
              // toast.error('Could not login..');
              console.log(response);
            }}
            cookiePolicy={"single_host_origin"}
          />
          <Box>
            <MicrosoftLogin
              buttonTheme="light_short"
              clientId={"f8c7976f-3e93-482d-88a3-62a1133cbbc3"}
              authCallback={authHandler}
            />
          </Box>
        </Flex>
      </Flex>
    </form>
  );
};

export default LoginForm;
