import { Text } from "@skodel/sk-ui";
import { Modal } from "@skodel/sk-ui";
import React from "react";

import LoginForm from "./LoginForm";

const LoginModal = ({ isOpen, onClose, ...otherProps }: any) => {
  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Login to Skodel"
    >
      <Modal.Header>
        <Text>Login to Skodel</Text>
        {/* <ModalCloseButton onClose={onClose} /> */}
      </Modal.Header>
      <Modal.Body>
        <LoginForm />
      </Modal.Body>
    </Modal.Base>
  );
};

export default LoginModal;
