import { useMutation } from "@apollo/react-hooks";
import { Text, Flex } from "@skodel/sk-ui";
import {
  Button,
  AccountSelect,
  ModalCloseButton,
  CountrySelect,
  FormLabel,
  Modal,
  Checkbox,
} from "@skodel/sk-ui";
import { TextInput } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { DropdownValue } from "../types";
import {
  GetOrganizations_organizations_edge_node,
  UpdateOrganizationData,
  UpdateOrganizationVariables,
} from "./types";

const UPDATE_ORGANIZATION = loader("./UpdateOrganization.graphql");

interface EditOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  organization: GetOrganizations_organizations_edge_node;
}

interface EditOrganizationFormValues {
  account: DropdownValue | null;
  country: DropdownValue | null;
  hasExecutiveProduct: boolean;
  name: string;
}

interface GetInitialEditOrganizationFormValuesParams {
  organization: GetOrganizations_organizations_edge_node;
}

const getInitialEditOrganizationFormValues = ({
  organization,
}: GetInitialEditOrganizationFormValuesParams): EditOrganizationFormValues => ({
  account: organization.account
    ? {
        label: organization.account.name,
        value: organization.account.id,
      }
    : null,
  country: organization.country
    ? {
        label: organization.country.name,
        value: organization.country.id,
      }
    : null,
  hasExecutiveProduct: organization.hasExecutiveProduct,
  name: organization.name,
});

const ERROR_MESSAGE_TYPENAMES = [
  "NoSuchObjectError",
  "ValidationError",
  "OperationForbidden",
  "OperationNotPermitted",
];

const EditOrganizationModal = ({
  isOpen,
  onClose,
  organization,
}: EditOrganizationModalProps) => {
  const [formState, setFormState] = useState<EditOrganizationFormValues>(() =>
    getInitialEditOrganizationFormValues({ organization })
  );

  const [updateOrganization, state] = useMutation<
    UpdateOrganizationData,
    UpdateOrganizationVariables
  >(UPDATE_ORGANIZATION, {
    onCompleted: (response) => {
      if (response.updateOrganization.success) {
        toast.success("Successfully updated organization.");

        onClose();
      } else {
        if (
          ERROR_MESSAGE_TYPENAMES.includes(
            response.updateOrganization.__typename
          )
        ) {
          toast.error(response.updateOrganization.message);
        } else {
          toast.error(
            "Could not update organization. Please check your internet connection and try again."
          );
        }
      }
    },
    onError: () => {
      toast.error(
        "Could not update organization. Please check your internet connection and try again."
      );
    },
  });

  const shouldInputBeDisabled = formState.name.length === 0 || state.loading;

  const onSubmit = () => {
    updateOrganization({
      variables: {
        id: organization.id,
        ...(formState.name === organization.name
          ? {}
          : { name: formState.name }),
        ...(formState.hasExecutiveProduct === organization.hasExecutiveProduct
          ? {}
          : { hasExecutiveProduct: formState.hasExecutiveProduct }),
        ...((formState.account?.value || null) ===
        (organization.account?.id || null)
          ? {}
          : { accountId: formState.account?.value || null }),
        ...((formState.country?.value || null) ===
        (organization.country?.id || null)
          ? {}
          : { countryId: formState.country?.value || null }),
      },
      refetchQueries: ["GetOrganizations"],
    });
  };

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Editing Organization"
    >
      <Modal.Header>
        <Text>Editing {organization.name}</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormLabel p={2} px={0} htmlFor="name">
          Name
        </FormLabel>
        <TextInput
          id="name"
          name="name"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              name: e.target.value,
            })
          }
          value={formState.name}
        />
        <FormLabel p={2} px={0} htmlFor="account">
          Account
        </FormLabel>

        <AccountSelect
          isSearchable
          isClearable
          onChange={(v: any) =>
            setFormState({
              ...formState,
              account: v,
            })
          }
          value={formState.account}
          id="account"
          defaultOptions
          name="account"
        />
        <FormLabel p={2} px={0} htmlFor="account">
          Country
        </FormLabel>

        <CountrySelect
          isSearchable
          isClearable
          onChange={(v: any) =>
            setFormState({
              ...formState,
              country: v,
            })
          }
          value={formState.country}
          id="country"
          defaultOptions
          name="country"
        />
        <Flex alignItems="center" justifyContent="flex-start" mt={2}>
          <Checkbox
            mr={2}
            checked={formState.hasExecutiveProduct}
            onChange={(newValue: any) => {
              setFormState({
                ...formState,
                hasExecutiveProduct: newValue,
              });
            }}
          />{" "}
          <Text>Has Executive Product</Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={shouldInputBeDisabled} onClick={onSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default EditOrganizationModal;
