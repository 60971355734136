import { useQuery } from "@apollo/react-hooks";
import { Flex, Table, Image, LanguageCodeSelect, Text } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React from "react";

import { IndexWrap } from "../organizations-dashboard";

const GetSupportSolutions = loader("./GetSupportSolutions.graphql");

export const SupportSolutionsDashboard = ({ history, location }: any) => {
  const [language, setLanguage] = React.useState<{
    value: string;
    label: string;
  } | null>(null);
  const { loading, data, error } = useQuery(GetSupportSolutions, {
    variables: {
      lang: language?.value,
    },
  });

  return (
    <IndexWrap
      history={history}
      location={location}
      rhsChildren={
        <Flex ml="5">
          {/* <Button
            variant="brand"
            disabled
            onClick={() => {
             
            }}
          >
            Create a support solution
          </Button> */}
        </Flex>
      }
    >
      <Flex alignItems="center" justifyContent="stretch">
        <Text mr="2">Language to show content in</Text>
        <Flex flex="1" flexDirection="column">
          <LanguageCodeSelect
            value={language}
            // @ts-ignore
            onChange={(
              language: {
                value: string;
                label: string;
              } | null
            ) => setLanguage(language)}
          />
        </Flex>
      </Flex>
      <Flex pt="3" justifyContent="stretch" alignItems="stretch" flex="1">
        <Table.Base>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderData
                style={{
                  width: "20%",
                }}
              >
                Image
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "20%",
                }}
              >
                Name
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "20%",
                }}
              >
                Author
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "20%",
                }}
              ></Table.HeaderData>
              {/* <Table.HeaderData
                style={{
                  width: "20%",
                }}
              >
                Positive Image
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "20%",
                }}
              >
                Negative Image
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "20%",
                }}
              >
                Positivity
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "20%",
                }}
              >
                Product Type
              </Table.HeaderData> */}
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {!loading &&
              !error &&
              data.supportSolutions.edges.map((edge: any, idx: any) => (
                <Table.BodyRow key={idx}>
                  <Table.BodyData>
                    <Image
                      src={edge.node.content.thumbnailUrl}
                      style={{
                        maxHeight: "80px",
                      }}
                    />
                  </Table.BodyData>
                  <Table.BodyData>{edge.node.content.name}</Table.BodyData>
                  <Table.BodyData>{edge.node.author.name}</Table.BodyData>
                  {edge.node.content.url && (
                    <Table.BodyData>
                      <a href={edge.node.content.url}>View File</a>
                    </Table.BodyData>
                  )}
                  {/* <Table.BodyData>
                    {edge.node.svgUrlForPositiveMood ? (
                      <img
                        style={{
                          width: "100px",
                          height: "auto",
                        }}
                        src={edge.node.svgUrlForPositiveMood}
                      />
                    ) : (
                      <Text color="#CCC">N/A</Text>
                    )}
                  </Table.BodyData>
                  <Table.BodyData>
                    {edge.node.svgUrlForNegativeMood ? (
                      <img
                        style={{
                          width: "100px",
                          height: "auto",
                        }}
                        src={edge.node.svgUrlForNegativeMood}
                      />
                    ) : (
                      <Text color="#CCC">N/A</Text>
                    )}
                  </Table.BodyData>
                  <Table.BodyData>
                    {edge.node.positivity ||
                    edge.node.positivity === "NEUTRAL" ? (
                      edge.node.positivity
                    ) : (
                      <Text color="#CCC">Neutral</Text>
                    )}
                  </Table.BodyData>
                  <Table.BodyData>
                    {edge.node.productType ? (
                      edge.node.productType
                    ) : (
                      <Text color="#CCC">All Products</Text>
                    )}
                  </Table.BodyData> */}
                </Table.BodyRow>
              ))}
          </Table.Body>
        </Table.Base>
      </Flex>
    </IndexWrap>
  );
};

export default SupportSolutionsDashboard;
