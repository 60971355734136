import { useMutation } from "@apollo/react-hooks";
import { Text } from "@skodel/sk-ui";
import { Button, ModalCloseButton, Modal } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React from "react";
import { toast } from "react-toastify";

const DeleteUser = loader("./DeleteUser.graphql");

const GetUserResetUrlModal = ({
  isOpen,
  onClose,
  user,
  ...otherProps
}: any) => {
  const [getResetUrl, getResetUrlState] = useMutation(DeleteUser);

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Get password reset URL for user"
    >
      <Modal.Header>
        <Text>Get password reset URL for user</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Text>
          Click below to generate a reset url for this users account. Subsequent
          use of forgot password may void this link.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          No
        </Button>
        <Button
          disabled={getResetUrlState.loading}
          onClick={() => {
            getResetUrl({
              variables: {
                id: user.id,
              },
              refetchQueries: ["GetUsers"],
            })
              .then((response: any) => {
                if (response.data.getResetUrl.success) {
                  toast.success(
                    "Successfully deleted user." +
                      JSON.stringify(response.data.getResetUrl)
                  );

                  onClose();
                } else {
                  if (response.data.getResetUrl.message) {
                    toast.error(response.data.getResetUrl.message);
                  } else {
                    toast.error(
                      "Could not request a reset URL for this user. Please check your internet connection and try again."
                    );
                  }
                }
              })
              .catch((err: any) =>
                toast.error(
                  "Could not request a reset URL for this user. Please check your internet connection and try again."
                )
              );
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default GetUserResetUrlModal;
