import { useMutation } from "@apollo/react-hooks";
import { Text } from "@skodel/sk-ui";
import { Button, ModalCloseButton, Modal } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React from "react";
import { toast } from "react-toastify";

const DeleteOrganization = loader("./DeleteOrganization.graphql");

const DeleteOrganizationModal = ({
  isOpen,
  onClose,
  organization,
  ...otherProps
}: any) => {
  const [deleteOrganization, deleteOrganizationState] =
    useMutation(DeleteOrganization);

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Delete Organization"
    >
      <Modal.Header>
        <Text>Deleting Organization</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Text>
          Are you sure you want to delete {organization.name}? This includes all
          users and data related to them and is irreversible.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          No
        </Button>
        <Button
          disabled={deleteOrganizationState.loading}
          onClick={() => {
            deleteOrganization({
              variables: {
                id: organization.id,
              },
              refetchQueries: ["GetOrganizations"],
            })
              .then((response: any) => {
                if (response.data.deleteOrganization.success) {
                  toast.success("Successfully deleted organization.");

                  onClose();
                } else {
                  if (
                    response.data.deleteOrganization.__typename ===
                    "NoSuchObjectError"
                  ) {
                    toast.error(response.data.deleteOrganization.message);
                  } else {
                    toast.error(
                      "Could not delete organization. Please check your internet connection and try again."
                    );
                  }
                }
              })
              .catch((err: any) =>
                toast.error(
                  "Could not delete organization. Please check your internet connection and try again."
                )
              );
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default DeleteOrganizationModal;
