import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  Table,
  Button,
  Flex,
  Pagination,
  TextInput,
  FormLabel,
  AccountSelect,
  OrganizationSelect,
} from "@skodel/sk-ui";
import { Pencil } from "@styled-icons/boxicons-solid/Pencil";
import { TrashAlt } from "@styled-icons/boxicons-solid/TrashAlt";
import { Sort } from "@styled-icons/fa-solid/Sort";
import { SortAmountDown } from "@styled-icons/fa-solid/SortAmountDown";
import { SortAmountUp } from "@styled-icons/fa-solid/SortAmountUp";
import { AddCircle } from "@styled-icons/remix-fill/AddCircle";
import { loader } from "graphql.macro";
import queryString from "query-string";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { IndexWrap } from "../organizations-dashboard";
import CreateDistributorModal from "./CreateDistributorModal";
import CreateFacilitatorModal from "./CreateFacilitatorModal";
import CreateLeaderModal from "./CreateLeaderModal";
import CreateOrgManagerModal from "./CreateOrgManagerModal";
import DeleteUserModal from "./DeleteUserModal";
import EditUserModal from "./EditUserModal";
import GetUserResetUrlModal from "./GetUserResetUrlModal";
import SetPasswordForUserModal from "./SetPasswordForUser";

const ImpersonateUser = loader("./ImpersonateUser.graphql");
const ResendActivationEmail = loader("./ResendActivationEmail.graphql");
const GetUsers = loader("./GetUsers.graphql");

const pushWithHook = (...args: any) => {
  // window.scrollTo(0, 0);
  args[0].push(args[1]);
};

export const getNewLocation = (feedParams: FeedParams, location: any) => {
  const paramsWithoutNullOrEmptyValues = Object.fromEntries(
    Object.entries(feedParams).filter(([_, v]) => v !== null && v !== "")
  );

  return {
    pathname: location.pathname,
    search: queryString.stringify(paramsWithoutNullOrEmptyValues),
  };
};

type FeedParams = {
  page?: number;
  count?: number;
  organizationId?: string;
  accountId?: string;
  emailQuery?: string;
  nameQuery?: string;
  sort?: string;
  sortDirection?: string;
};

export const getFeedParams: (location: any) => FeedParams = (location: any) => {
  const query: {
    page?: string;
    count?: string;
    organizationId?: string;
    accountId?: string;
    emailQuery?: string;
    nameQuery?: string;
    sort?: string;
    sortDirection?: string;
  } = queryString.parse(location.search);

  const feedParams: FeedParams = {
    page: 1,
  };

  if (query.page) {
    feedParams.page = parseInt(query.page || "1");
  }

  if (query.count) {
    feedParams.page = parseInt(query.count || "20");
  }

  if (query.organizationId) {
    feedParams.organizationId = query.organizationId;
  }

  if (query.accountId) {
    feedParams.accountId = query.accountId;
  }

  if (query.sort) {
    feedParams.sort = query.sort;
  }

  if (query.sortDirection) {
    feedParams.sortDirection = query.sortDirection;
  }

  if (query.emailQuery) {
    feedParams.emailQuery = query.emailQuery;
  }

  if (query.nameQuery) {
    feedParams.nameQuery = query.nameQuery;
  }

  return feedParams;
};

const UserAccountType = ({ accountType }: any) => {
  if (accountType === "USER") {
    return <>user</>;
  }

  if (accountType === "WELLBEING_LEADER") {
    return <>Wellbeing Leader</>;
  }

  if (accountType === "WELLBEING_FACILITATOR") {
    return <>Wellbeing Facilitator</>;
  }

  if (accountType === "DISTRIBUTOR") {
    return <>Distributor</>;
  }

  if (accountType === "GLOBAL_ADMINISTRATOR") {
    return <>Global Administrator</>;
  }

  if (accountType === "ORG_MANAGER") {
    return <>Org Manager</>;
  }

  return <>Unknown</>;
};

const UserActions = ({
  user,
  setShowDeleteUserModal,
  setShowGetResetUrl,
  setShowSetPassword,
}: any) => {
  const [resendActivationEmail, resendActivationEmailState]: any = useMutation(
    ResendActivationEmail
  );

  const [impersonateUser, impersonateUserState]: any =
    useMutation(ImpersonateUser);

  return (
    <Flex
      style={{
        gap: "4px",
        display: "grid",
        gridTemplateColumns: "1fr",
      }}
    >
      {user.node.canUpdate && !user.node.hasOnboarded && (
        <Button
          variant="linkSmall"
          disabled={resendActivationEmailState.loading}
          onClick={() => {
            resendActivationEmail({
              variables: {
                userId: user.node.id,
              },
            })
              .then((data: any) =>
                toast.success("Successfully sent activation email.")
              )
              .catch((err: any) =>
                toast.error(
                  "Could not send activation email. Please check your internet connection and try again."
                )
              );
          }}
        >
          Resend Activation Email
        </Button>
      )}
      {user.node.canUpdate &&
        user.node.hasOnboarded &&
        user.node.accountType !== "INTEGRATION" && (
          <Button
            variant="linkSmall"
            disabled={impersonateUserState.loading}
            onClick={() => {
              impersonateUser({
                variables: {
                  userId: user.node.id,
                },
                refetchQueries: ["GetUserAccountImpersonation"],
              })
                .then((data: any) =>
                  toast.success(
                    "Successfully impersonated user. Refresh to see effects. Some apps may not work if you are impersonating an underprivileged user."
                  )
                )
                .catch((err: any) =>
                  toast.error(
                    "Could not impersonate user. Please check your internet connection and try again."
                  )
                );
            }}
          >
            Impersonate
          </Button>
        )}

      {user.node.canUpdate && (
        <Button
          variant="linkSmall"
          onClick={() =>
            setShowSetPassword({
              enabled: true,
              user: user.node,
            })
          }
        >
          Set Password
        </Button>
      )}
      {/* {user.node.canUpdate && (
        <Button variant="link" 
          onClick={() =>
            setShowGetResetUrl({
              enabled: true,
              user: user.node,
            })
          }
        >
          Get Reset URL
        </Button>
      )} */}

      {user.node.canDelete && (
        <Button
          variant="linkSmall"
          onClick={() =>
            setShowDeleteUserModal({
              enabled: true,
              user: user.node,
            })
          }
        >
          <TrashAlt width="1em" height="1em" />
        </Button>
      )}
    </Flex>
  );
};

const SortController = ({
  sort,
  sortDirection,
  columnId,
  setCurrentSort,
}: {
  sort?: string | null;
  sortDirection?: string | null;
  columnId: string;
  setCurrentSort: (
    newSort: string | null,
    newSortDirection: string | null
  ) => void;
}) => {
  const isActive = sort === columnId;
  if (isActive) {
    if (sortDirection === "DESC") {
      return (
        <Button
          onClick={() => {
            setCurrentSort(null, null);
          }}
          variant="link"
          p={1}
        >
          <SortAmountDown width="1em" height="1em" />
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            setCurrentSort(columnId, "DESC");
          }}
          variant="link"
          p={1}
        >
          <SortAmountUp width="1em" height="1em" />
        </Button>
      );
    }
  } else {
    return (
      <Button
        onClick={() => {
          setCurrentSort(columnId, "ASC");
        }}
        variant="link"
        p={1}
      >
        <Sort width="1em" height="1em" />
      </Button>
    );
  }
};

export const UsersDashboard = ({ history }: any) => {
  const [showCreateFacilitatorModal, setShowCreateFacilitatorModal]: any =
    useState({
      enabled: false,
    });

  const [showCreateLeaderModal, setShowCreateLeaderModal]: any = useState({
    enabled: false,
  });

  const [showCreateDistributorModal, setShowCreateDistributorModal]: any =
    useState({
      enabled: false,
    });

  const [showCreateOrgManagerModal, setShowCreateOrgManagerModal]: any =
    useState({
      enabled: false,
    });

  const [showDeleteUserModal, setShowDeleteUserModal]: any = useState({
    enabled: false,
  });

  const [showEditUserModal, setShowEditUserModal]: any = useState({
    enabled: false,
  });

  const [showGetResetUrl, setShowGetResetUrl]: any = useState({
    enabled: false,
  });

  const [showSetPassword, setShowSetPassword]: any = useState({
    enabled: false,
  });

  const [resendActivationEmail, resendActivationEmailState]: any = useMutation(
    ResendActivationEmail
  );

  const setEmailQuery = (emailQuery: string) => {
    pushWithHook(
      history,
      getNewLocation(
        {
          ...feedParams,
          page: 1,
          emailQuery,
        },
        location
      )
    );
  };

  const setNameQuery = (nameQuery: string) => {
    pushWithHook(
      history,
      getNewLocation(
        {
          ...feedParams,
          page: 1,
          nameQuery,
        },
        location
      )
    );
  };

  const setCurrentSort = (
    sort: string | null,
    sortDirection: string | null
  ) => {
    pushWithHook(
      history,
      getNewLocation(
        {
          ...feedParams,
          page: 1,
          ...(sort && sortDirection
            ? { sort, sortDirection }
            : {
                sort: undefined,
                sortDirection: undefined,
              }),
        },
        location
      )
    );
  };

  const setAccountIdFilter = (accountId?: { value: string }) => {
    pushWithHook(
      history,
      getNewLocation(
        {
          ...feedParams,
          page: 1,
          accountId: accountId?.value,
        },
        location
      )
    );
  };

  const setOrganizationIdFilter = (organizationId?: { value: string }) => {
    pushWithHook(
      history,
      getNewLocation(
        {
          ...feedParams,
          page: 1,
          organizationId: organizationId?.value,
        },
        location
      )
    );
  };

  const location = useLocation();
  const feedParams = getFeedParams(location);

  const { loading, data, error } = useQuery(GetUsers, {
    variables: {
      ...feedParams,
      organizationIdIn: feedParams.organizationId
        ? [feedParams.organizationId]
        : null,

      accountIdIn: feedParams.accountId ? [feedParams.accountId] : null,
      sorts: feedParams.sort
        ? [`${feedParams.sort}_${feedParams.sortDirection}`]
        : [],
    },
  });

  const client = useApolloClient();

  return (
    <IndexWrap
      history={history}
      location={location}
      flexDirection="column"
      rhsChildren={
        <Flex ml="5">
          <Button
            variant="brand"
            onClick={() =>
              setShowCreateOrgManagerModal({
                enabled: true,
              })
            }
            mr={2}
          >
            <AddCircle
              width="1em"
              height="1em"
              style={{
                marginRight: "6px",
              }}
            />
            Org Manager
          </Button>
          <Button
            variant="brand"
            onClick={() =>
              setShowCreateLeaderModal({
                enabled: true,
              })
            }
            mr={2}
          >
            <AddCircle
              width="1em"
              height="1em"
              style={{
                marginRight: "6px",
              }}
            />
            Leader
          </Button>
          <Button
            variant="brand"
            onClick={() =>
              setShowCreateFacilitatorModal({
                enabled: true,
              })
            }
            mr={2}
          >
            <AddCircle
              width="1em"
              height="1em"
              style={{
                marginRight: "6px",
              }}
            />
            Facilitator
          </Button>
          <Button
            variant="brand"
            onClick={() =>
              setShowCreateDistributorModal({
                enabled: true,
              })
            }
          >
            <AddCircle
              width="1em"
              height="1em"
              style={{
                marginRight: "6px",
              }}
            />
            Distributor
          </Button>
        </Flex>
      }
    >
      <Flex flexDirection="column">
        <Flex
          my="2"
          flexDirection="column"
          style={{
            gap: "8px",
          }}
        >
          <TextInput
            onChange={(e) => setEmailQuery(e.target.value)}
            placeholder="Email"
            value={feedParams.emailQuery}
          />
          <TextInput
            onChange={(e) => setNameQuery(e.target.value)}
            placeholder="Name"
            value={feedParams.nameQuery}
          />

          <AccountSelect
            isClearable
            // @ts-ignore
            onChange={setAccountIdFilter}
            placeholder="Account"
            value={
              feedParams.accountId
                ? {
                    value: feedParams.accountId,
                    label: feedParams.accountId,
                  }
                : null
            }
          />
          <OrganizationSelect
            isClearable
            // @ts-ignore
            onChange={setOrganizationIdFilter}
            placeholder="Organization"
            value={
              feedParams.organizationId
                ? {
                    value: feedParams.organizationId,
                    label: feedParams.organizationId,
                  }
                : null
            }
          />
        </Flex>

        <Table.Base>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderData
                style={{
                  width: "30%",
                }}
              >
                Name
                <SortController
                  sort={feedParams.sort}
                  sortDirection={feedParams.sortDirection}
                  columnId={"FULL_NAME"}
                  setCurrentSort={setCurrentSort}
                />
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "5%",
                }}
              >
                Email
                <SortController
                  sort={feedParams.sort}
                  sortDirection={feedParams.sortDirection}
                  columnId={"EMAIL"}
                  setCurrentSort={setCurrentSort}
                />
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "10%",
                }}
              >
                Organization
                {/* <SortController
                  sort={feedParams.sort}
                  sortDirection={feedParams.sortDirection}
                  columnId={"name"}
                  setCurrentSort={setCurrentSort}
                /> */}
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "15%",
                }}
              >
                Account Type
                <SortController
                  sort={feedParams.sort}
                  sortDirection={feedParams.sortDirection}
                  columnId={"ACCOUNT_TYPE"}
                  setCurrentSort={setCurrentSort}
                />
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "30%",
                }}
              ></Table.HeaderData>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {!loading &&
              !error &&
              data.users.edges.map((user: any, idx: any) => (
                <Table.BodyRow key={idx}>
                  <Table.BodyData>
                    {/* <Link to={`/user/${user.node.id}`}> */}
                    {user.node.displayName}

                    {user.node.canUpdate && (
                      <Button
                        variant="linkSmall"
                        ml="2"
                        onClick={() =>
                          setShowEditUserModal({
                            enabled: true,
                            user: user.node,
                          })
                        }
                      >
                        <Pencil width="1em" height="1em" />
                      </Button>
                    )}
                    {/* </Link> */}
                  </Table.BodyData>
                  <Table.BodyData>
                    <FormLabel>{user.node.email}</FormLabel>
                  </Table.BodyData>
                  <Table.BodyData>{user.node.organization.name}</Table.BodyData>
                  <Table.BodyData>
                    <FormLabel>
                      <UserAccountType accountType={user.node.accountType} />
                    </FormLabel>
                  </Table.BodyData>
                  <Table.BodyData
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <UserActions
                      user={user}
                      setShowDeleteUserModal={setShowDeleteUserModal}
                      setShowGetResetUrl={setShowGetResetUrl}
                      setShowSetPassword={setShowSetPassword}
                    />
                  </Table.BodyData>
                </Table.BodyRow>
              ))}
          </Table.Body>
        </Table.Base>
      </Flex>
      {showDeleteUserModal.enabled && (
        <DeleteUserModal
          isOpen={showDeleteUserModal.enabled}
          user={showDeleteUserModal.user}
          onClose={() => {
            setShowDeleteUserModal({
              enabled: false,
            });
          }}
        />
      )}

      {showEditUserModal.enabled && (
        <EditUserModal
          isOpen={showEditUserModal.enabled}
          user={showEditUserModal.user}
          onClose={() => {
            setShowEditUserModal({
              enabled: false,
            });
          }}
        />
      )}
      {showGetResetUrl.enabled && (
        <GetUserResetUrlModal
          isOpen={showGetResetUrl.enabled}
          user={showGetResetUrl.user}
          onClose={() => {
            setShowGetResetUrl({
              enabled: false,
            });
          }}
        />
      )}
      {showSetPassword.enabled && (
        <SetPasswordForUserModal
          isOpen={showSetPassword.enabled}
          user={showSetPassword.user}
          onClose={() => {
            setShowSetPassword({
              enabled: false,
            });
          }}
        />
      )}
      {showCreateLeaderModal.enabled && (
        <CreateLeaderModal
          isOpen={showCreateLeaderModal.enabled}
          onClose={() => {
            setShowCreateLeaderModal({
              enabled: false,
            });
          }}
        />
      )}
      {showCreateFacilitatorModal.enabled && (
        <CreateFacilitatorModal
          isOpen={showCreateFacilitatorModal.enabled}
          onClose={() => {
            setShowCreateFacilitatorModal({
              enabled: false,
            });
          }}
        />
      )}
      {showCreateDistributorModal.enabled && (
        <CreateDistributorModal
          isOpen={showCreateDistributorModal.enabled}
          onClose={() => {
            setShowCreateDistributorModal({
              enabled: false,
            });
          }}
        />
      )}
      {showCreateOrgManagerModal.enabled && (
        <CreateOrgManagerModal
          isOpen={showCreateOrgManagerModal.enabled}
          onClose={() => {
            setShowCreateOrgManagerModal({
              enabled: false,
            });
          }}
        />
      )}

      <Flex flex="1" justifyContent="flex-end" px={3}>
        {data && data.users.pageInfo && feedParams && getNewLocation && (
          <Pagination
            pageInfo={data.users.pageInfo}
            feedParams={feedParams as any}
            getNewLocation={getNewLocation as any}
          />
        )}
      </Flex>
    </IndexWrap>
  );
};

export default UsersDashboard;
