// src/Login.js
import { Flex } from "@skodel/sk-ui";
import { useHasAuthenticatedSuccessfully } from "@skodel/sk-ui";
import React from "react";
import { Redirect } from "react-router-dom";

import LoginForm from "./LoginForm";

// @ts-ignore

const Login = () => {
  const hasAuthenticatedSuccessfully = useHasAuthenticatedSuccessfully();

  return hasAuthenticatedSuccessfully !== false ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    // @ts-ignore
    <Flex
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: "100vh",
      }}
    >
      <LoginForm />
    </Flex>
  );
};

export default Login;
