import { useQuery } from "@apollo/react-hooks";
import { Flex } from "@skodel/sk-ui";
import { Button, Table } from "@skodel/sk-ui";
import { Pagination, getFeedParams, getNewLocation } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { IndexWrap } from "../organizations-dashboard";
import CreateAccountModal from "./CreateAccountModal";
import DeleteAccountModal from "./DeleteAccountModal";
import EditAccountModal from "./EditAccountModal";

const GetAccounts = loader("./GetAccounts.graphql");

export const AccountsDashboard = ({ history }: any) => {
  const location = useLocation();
  const feedParams = getFeedParams(location);

  const [showCreateModal, setShowCreateModal]: any = useState({
    enabled: false,
  });
  const [showEditAccountModal, setShowEditAccountModal]: any = useState({
    enabled: false,
  });

  const [showDeleteAccountModal, setShowDeleteAccountModal]: any = useState({
    enabled: false,
  });

  const { loading, data, error } = useQuery(GetAccounts, {
    variables: {
      ...feedParams,
    },
  });

  return (
    <IndexWrap
      history={history}
      location={location}
      rhsChildren={
        <Flex ml="5">
          <Button
            variant="brand"
            onClick={() =>
              setShowCreateModal({
                enabled: true,
              })
            }
          >
            Create a new account
          </Button>
        </Flex>
      }
    >
      <Flex
        pt="3"
        justifyContent="stretch"
        alignItems="stretch"
        flex="1"
        flexDirection="column"
      >
        <Table.Base>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderData
                style={{
                  width: "90%",
                }}
              >
                Name
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "10%",
                }}
              ></Table.HeaderData>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {!loading &&
              !error &&
              data.accounts.edges.map((edge: any, idx: any) => (
                <Table.BodyRow key={idx}>
                  <Table.BodyData>{edge.node.name}</Table.BodyData>
                  <Table.BodyData
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="link"
                      px="2"
                      onClick={() =>
                        setShowEditAccountModal({
                          enabled: true,
                          account: edge.node,
                        })
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      variant="link"
                      px="2"
                      onClick={() =>
                        setShowDeleteAccountModal({
                          enabled: true,
                          account: edge.node,
                        })
                      }
                    >
                      Delete
                    </Button>
                  </Table.BodyData>
                </Table.BodyRow>
              ))}
          </Table.Body>
        </Table.Base>
        {showDeleteAccountModal.enabled && (
          <DeleteAccountModal
            isOpen={showDeleteAccountModal.enabled}
            account={showDeleteAccountModal.account}
            onClose={() => {
              setShowDeleteAccountModal({
                enabled: false,
              });
            }}
          />
        )}
        {showEditAccountModal.enabled && (
          <EditAccountModal
            isOpen={showEditAccountModal.enabled}
            account={showEditAccountModal.account}
            onClose={() => {
              setShowEditAccountModal({
                enabled: false,
              });
            }}
          />
        )}
        {showCreateModal.enabled && (
          <CreateAccountModal
            isOpen={showCreateModal.enabled}
            onClose={() => {
              setShowCreateModal({
                enabled: false,
              });
            }}
          />
        )}

        <Flex flex="1" justifyContent="flex-end" px={3}>
          {data && data.accounts.pageInfo && feedParams && getNewLocation && (
            <Pagination
              pageInfo={data.accounts.pageInfo}
              feedParams={feedParams}
              getNewLocation={getNewLocation}
            />
          )}
        </Flex>
      </Flex>
    </IndexWrap>
  );
};

export default AccountsDashboard;
