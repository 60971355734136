import { useQuery, useApolloClient } from "@apollo/react-hooks";
import {
  ImpersonationCheck,
  Flex,
  Text,
  Button,
  InlineLoader,
  getGlobalSessionStorage,
  LogoImage,
} from "@skodel/sk-ui";
import { ArrowDropDown } from "@styled-icons/remix-line/ArrowDropDown";
import FileSaver from "file-saver";
import gql from "graphql-tag";
import { loader } from "graphql.macro";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";

const GET_VIEWER_META = loader("./GetViewerMeta.graphql");

const UserWidget = () => {
  const history = useHistory();
  const client = useApolloClient();
  const { i18n } = useTranslation();

  const { loading, error, data } = useQuery(GET_VIEWER_META, {
    pollInterval: 1000 * 30, // every ten seconds
  });

  if (loading) {
    return <InlineLoader />;
  }

  if (error) {
    return (
      <Button
        variant="brand"
        onClick={() => {
          getGlobalSessionStorage().logout(i18n);
          history.push("/login");
        }}
      >
        Logout
      </Button>
    );
  }

  return (
    <Text>
      <Dropdown
        trigger={
          <>
            Hi, {data.viewer.firstName} {data.viewer.lastName}.{" "}
            <ArrowDropDown width="24px" height="24px" />
          </>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item
            text="Logout"
            onClick={() => {
              getGlobalSessionStorage().logout(i18n);
              history.push("/login");
            }}
          />
          <Dropdown.Item
            text="Export Stats"
            onClick={() => {
              toast.info("Downloading export, hold on..");
              const z = client
                .query({
                  query: gql`
                    {
                      selfCheckInCountByMonthCsv(samples: 30)
                    }
                  `,
                })
                .then(({ data }: any) => {
                  var blob = new Blob([data.selfCheckInCountByMonthCsv], {
                    type: "text/csv;charset=utf-8",
                  });
                  FileSaver.saveAs(
                    blob,
                    `skodel-confidential-stats-output-${new Date().getTime()}.csv`
                  );
                });
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Text>
  );
};

const Navbar = ({ showSearchAndUser, history, ...otherProps }: any) => (
  <Flex
    // bg="#FFFFFF"
    justifyContent="center"
    p={3}
    style={{
      borderTop: "1px solid #e8e8e8",
    }}
    {...otherProps}
  >
    <Flex
      style={{ maxWidth: "1080px", flex: "1" }}
      justifyContent="space-between"
      flexDirection="column"
    >
      <ImpersonationCheck />
      <Flex justifyContent="space-between">
        <Link to="/">
          <LogoImage />
        </Link>
        {showSearchAndUser && (
          <Flex>
            <UserWidget />
          </Flex>
        )}
      </Flex>
    </Flex>
  </Flex>
);

export default Navbar;
