import { useMutation } from "@apollo/react-hooks";
import { Text, FormLabel } from "@skodel/sk-ui";
import { Button, Modal, ModalCloseButton } from "@skodel/sk-ui";
import { TextInput } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { toast } from "react-toastify";

const CreateAccount = loader("./CreateAccount.graphql");

const CreateAccountModal = ({ isOpen, onClose, ...otherProps }: any) => {
  const [formState, setFormState]: any = useState({
    name: "",
  });

  const [createAccount, state] = useMutation(CreateAccount);

  const shouldInputBeDisabled = formState.name.length === 0 || state.loading;

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Create Account"
    >
      <Modal.Header>
        <Text>Create Account</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormLabel p={2} px={0} htmlFor="name">
          Name
        </FormLabel>
        <TextInput
          id="name"
          name="name"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              name: e.target.value,
            })
          }
          value={formState.name}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={shouldInputBeDisabled}
          onClick={() => {
            createAccount({
              variables: {
                name: formState.name,
              },
              refetchQueries: ["GetAccounts"],
            })
              .then((response: any) => {
                if (response.data.createAccount.success) {
                  toast.success("Successfully created account.");

                  onClose();
                } else {
                  if (
                    response.data.createAccount.__typename === "ValidationError"
                  ) {
                    toast.error(response.data.createAccount.message);
                  } else {
                    toast.error(
                      "Could not create account. Please check your internet connection and try again."
                    );
                  }
                }
              })
              .catch((err: any) =>
                toast.error(
                  "Could not create account. Please check your internet connection and try again."
                )
              );
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default CreateAccountModal;
