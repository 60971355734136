import { ApolloProvider as ApolloNewProvider } from "@apollo/client";
import { ApolloProvider as ApolloHookProvider } from "@apollo/react-hooks";
import {
  Theme,
  GlobalBaseStyle,
  useAppApolloClient,
  ProductTypeProvider,
} from "@skodel/sk-ui";
// @ts-ignore
import { ThemeProvider } from "emotion-theming";
import { createBrowserHistory } from "history";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { Router } from "react-router";
import { Route, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components";

// @ts-ignore
import Login from "./Login";
import AppRouter from "./Router";
import { QueryProviders } from "./components/query-context";

const history = createBrowserHistory();

const FontFix = createGlobalStyle`
@font-face {
    font-family: 'Nunito Sans Regular';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Regular'), url('/fonts/NunitoSans-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Italic'), url('/fonts/NunitoSans-Italic.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans ExtraLight';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans ExtraLight'), url('/fonts/NunitoSans-ExtraLight.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans ExtraLight Italic'), url('/fonts/NunitoSans-ExtraLightItalic.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans Light';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Light'), url('/fonts/NunitoSans-Light.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans Light Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Light Italic'), url('/fonts/NunitoSans-LightItalic.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans SemiBold';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans SemiBold'), url('/fonts/NunitoSans-SemiBold.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans SemiBold Italic'), url('/fonts/NunitoSans-SemiBoldItalic.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans Bold';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Bold'), url('/fonts/NunitoSans-Bold.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans Bold Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Bold Italic'), url('/fonts/NunitoSans-BoldItalic.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans ExtraBold';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans ExtraBold'), url('/fonts/NunitoSans-ExtraBold.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans ExtraBold Italic'), url('/fonts/NunitoSans-ExtraBoldItalic.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans Black';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Black'), url('/fonts/NunitoSans-Black.woff') format('woff');
}


@font-face {
    font-family: 'Nunito Sans Black Italic';
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: local('Nunito Sans Black Italic'), url('/fonts/NunitoSans-BlackItalic.woff') format('woff');
}
`;

function App() {
  return (
    <Suspense fallback={<div></div>}>
      <Router history={history}>
        <RouteWrapper />
      </Router>
    </Suspense>
  );
}

const RouteWrapper = () => {
  const client = useAppApolloClient();

  return (
    <ProductTypeProvider>
      <ThemeProvider theme={Theme}>
        <GlobalBaseStyle />
        <FontFix />
        <QueryProviders>
          <ApolloNewProvider client={client}>
            {/* @ts-ignore */}
            <ApolloHookProvider client={client}>
              <Helmet
                defaultTitle="Skodel Admin"
                titleTemplate="Skodel Admin - %s"
              ></Helmet>
              <Switch>
                <Route path="/login" render={() => <Login />} />
                <AppRouter />
              </Switch>
              <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
            </ApolloHookProvider>
          </ApolloNewProvider>
        </QueryProviders>
      </ThemeProvider>
    </ProductTypeProvider>
  );
};

export default App;
