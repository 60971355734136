import { useMutation } from "@apollo/react-hooks";
import { Text } from "@skodel/sk-ui";
import { Button, ModalCloseButton, Modal } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React from "react";
import { toast } from "react-toastify";

const DeleteUser = loader("./DeleteUser.graphql");

const DeleteUserModal = ({ isOpen, onClose, user, ...otherProps }: any) => {
  const [deleteUser, deleteUserState] = useMutation(DeleteUser);

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Delete User"
    >
      <Modal.Header>
        <Text>Deleting User</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Text>
          Are you sure you want to delete {user.firstName} {user.lastName}'s
          account? This includes all data related to them and is irreversible.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          No
        </Button>
        <Button
          disabled={deleteUserState.loading}
          onClick={() => {
            deleteUser({
              variables: {
                id: user.id,
              },
              refetchQueries: ["GetUsers"],
            })
              .then((response: any) => {
                if (response.data.deleteUser.success) {
                  toast.success("Successfully deleted user.");

                  onClose();
                } else {
                  if (
                    response.data.deleteUser.__typename === "NoSuchObjectError"
                  ) {
                    toast.error(response.data.deleteUser.message);
                  } else if (
                    response.data.deleteUser.__typename === "OperationForbidden"
                  ) {
                    toast.error(response.data.deleteUser.message);
                  } else {
                    toast.error(
                      "Could not delete user. Please check your internet connection and try again."
                    );
                  }
                }
              })
              .catch((err: any) =>
                toast.error(
                  "Could not delete user. Please check your internet connection and try again."
                )
              );
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default DeleteUserModal;
