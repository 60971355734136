import { Flex } from "@skodel/sk-ui";
import React from "react";

import Navbar from "./components/navbar";

export const PageWrapper = ({ children, history }: any) => (
  <>
    <Navbar showSearchAndUser history={history} />
    <Flex margin="0 auto" flexDirection="column" alignItems="center">
      {children}
    </Flex>
  </>
);
