import { useMutation, useApolloClient } from "@apollo/react-hooks";
import {
  Text,
  ModalCloseButton,
  FormLabel,
  TextInput,
  Button,
  Modal,
} from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { toast } from "react-toastify";

const UpdateAccount = loader("./UpdateAccount.graphql");

const EditAccountModal = ({ isOpen, onClose, account, ...otherProps }: any) => {
  const client = useApolloClient();

  const [formState, setFormState]: any = useState({
    name: account.name,
  });

  const [updateAccount, state] = useMutation(UpdateAccount);

  const shouldInputBeDisabled = formState.name.length === 0 || state.loading;

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Editing Account"
    >
      <Modal.Header>
        <Text>Editing {account.name}</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormLabel p={2} px={0} htmlFor="name">
          Name
        </FormLabel>
        <TextInput
          id="name"
          name="name"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              name: e.target.value,
            })
          }
          value={formState.name}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={shouldInputBeDisabled}
          onClick={() => {
            updateAccount({
              variables: {
                id: account.id,
                ...(formState.name === account.name
                  ? {}
                  : { name: formState.name }),
              },
            })
              .then((response: any) => {
                if (response.data.updateAccount.success) {
                  toast.success("Successfully updated account.");

                  onClose();
                } else {
                  if (
                    response.data.updateAccount.__typename ===
                    "NoSuchObjectError"
                  ) {
                    toast.error(response.data.updateAccount.message);
                  } else if (
                    response.data.updateAccount.__typename === "ValidationError"
                  ) {
                    toast.error(response.data.updateAccount.message);
                  } else {
                    toast.error(
                      "Could not update account. Please check your internet connection and try again."
                    );
                  }
                }
              })
              .catch((err: any) =>
                toast.error(
                  "Could not update account. Please check your internet connection and try again."
                )
              );
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default EditAccountModal;
