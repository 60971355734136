import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import {
  Flex,
  Button,
  Table,
  VerticalTabs,
  Tab,
  TextInput,
} from "@skodel/sk-ui";
import {
  Pagination,
  MaxWidthContainer,
  CountrySelect,
  AccountSelect,
} from "@skodel/sk-ui";
import * as FileSaver from "file-saver";
import { loader } from "graphql.macro";
import queryString from "query-string";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import CreateOrganizationModal from "./CreateOrganizationModal";
import DeleteOrganizationModal from "./DeleteOrganizationModal";
import EditOrganizationModal from "./EditOrganizationModal";
import {
  GetOrganizations,
  GetOrganizations_organizations_edge,
  GetOrganizations_organizations_edge_node,
} from "./types";

const GetCheckInExportForOrg = loader("./GetCheckInExportForOrg.graphql");
const AddSampleData = loader("./AddSampleData.graphql");
const GET_ORGANIZATIONS = loader("./GetOrganizations.graphql");

type ModalState<T = {}> = T & {
  enabled: boolean;
};

type OrganizationModalState = ModalState<{
  organization?: GetOrganizations_organizations_edge_node;
}>;

const pushWithHook = (...args: any) => {
  // window.scrollTo(0, 0);
  args[0].push(args[1]);
};

export const getNewLocation = (feedParams: FeedParams, location: any) => {
  const paramsWithoutNullOrEmptyValues = Object.fromEntries(
    Object.entries(feedParams).filter(([_, v]) => v !== null && v !== "")
  );

  return {
    pathname: location.pathname,
    search: queryString.stringify(paramsWithoutNullOrEmptyValues),
  };
};

type FeedParams = {
  page?: number;
  count?: number;
  accountId?: string;
  countryId?: string;
  query?: string;
};

export const getFeedParams: (location: any) => FeedParams = (location: any) => {
  const query: {
    page?: string;
    count?: string;
    accountId?: string;
    countryId?: string;
    query?: string;
  } = queryString.parse(location.search);

  const feedParams: FeedParams = {
    page: 1,
  };

  if (query.page) {
    feedParams.page = parseInt(query.page || "1");
  }

  if (query.count) {
    feedParams.page = parseInt(query.count || "20");
  }

  if (query.accountId) {
    feedParams.accountId = query.accountId;
  }

  if (query.countryId) {
    feedParams.countryId = query.countryId;
  }

  if (query.query) {
    feedParams.query = query.query;
  }

  return feedParams;
};

export const INDEX_PAGE_TABS: Array<Tab> = [
  {
    id: "TAGS",
    label: "Tags",
    getUrl: (params) => "/tags",
  },
  {
    id: "SUPPORT_SOLUTIONS",
    label: "Support Solutions",
    getUrl: (params) => "/support-solutions",
  },
  {
    id: "ACCOUNTS",
    label: "Accounts",
    getUrl: (params) => "/accounts",
  },
  {
    id: "ORGANIZATIONS",
    label: "Organizations",
    getUrl: (params) => "/organizations",
  },
  {
    id: "USERS",
    label: "Users",
    getUrl: (params) => "/users",
  },
];

export const IndexWrap = ({
  history,
  location,
  children,
  rhsChildren,
}: any) => {
  const currentTabIdx = INDEX_PAGE_TABS.findIndex(
    (value: Tab, idx) => location.pathname === value.getUrl({})
  );

  return (
    <>
      <MaxWidthContainer
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <VerticalTabs
          tabs={INDEX_PAGE_TABS}
          isTabActive={(tab: any, tabIdx: number) => {
            return tabIdx === currentTabIdx;
          }}
          changeTab={(tab: any, tabIdx: number) => {
            history.push(tab.getUrl({}));
          }}
        />
        <Flex ml="4">{rhsChildren}</Flex>
      </MaxWidthContainer>
      <MaxWidthContainer>{children}</MaxWidthContainer>
    </>
  );
};

export const ExportCheckInsButton = ({ org }: any) => {
  const [exportCheckIn, exportCheckInState] = useMutation(
    GetCheckInExportForOrg,
    {
      variables: {
        id: org.id,
      },
    }
  );

  return (
    <Button
      variant="link"
      disabled={exportCheckInState.loading}
      onClick={() => {
        exportCheckIn()
          .then((res: any) => {
            var now = new Date();

            let blob = new Blob([res.data.checkInExport], {
              type: "text/csv",
            });
            FileSaver.saveAs(
              blob,
              `${org.name}-${now.getDate()}-${
                now.getMonth() + 1
              }-${now.getFullYear()}.csv`
            );
          })
          .catch((err: any) => {
            toast.error("Could not export check ins.");
          });
      }}
    >
      Export CSV
    </Button>
  );
};

interface OrganizationRowProps {
  edge: GetOrganizations_organizations_edge;
  idx: number;
  setShowEditOrganizationModal: React.Dispatch<
    React.SetStateAction<OrganizationModalState>
  >;
  setShowDeleteOrganizationModal: React.Dispatch<
    React.SetStateAction<OrganizationModalState>
  >;
}

const OrganizationRow = ({
  edge,
  idx,
  setShowEditOrganizationModal,
  setShowDeleteOrganizationModal,
}: OrganizationRowProps) => {
  const [disableAddSampleData, setDisableAddSampleData] = useState(false);
  const [addSampleData, mutationState] = useMutation(AddSampleData);

  return (
    <Table.BodyRow key={idx}>
      <Table.BodyData>
        {/* <Link to={`/organization/${edge.node.id}`}>{edge.node.name}</Link> */}
        {edge.node.name}
      </Table.BodyData>
      <Table.BodyData>
        {edge.node.account ? edge.node.account.name : "N/A"}
      </Table.BodyData>
      <Table.BodyData>
        {edge.node.hasExecutiveProduct ? "Yes" : "No"}
      </Table.BodyData>
      <Table.BodyData>
        {edge.node.hasOrganizationManager ? "Yes" : "No"}
      </Table.BodyData>
      <Table.BodyData>
        {edge.node.country ? edge.node.country.name : "N/A"}
      </Table.BodyData>

      <Table.BodyData
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="link"
          disabled={mutationState.loading}
          onClick={() => {
            setDisableAddSampleData(true);
            addSampleData({
              variables: {
                id: edge.node.id,
              },
            })
              .then((data) =>
                toast.success("Successfully added sample data to organization.")
              )
              .catch((err: any) =>
                toast.error(
                  "Could not add sample data to organization. Please check your internet connection and try again."
                )
              );
          }}
          px="2"
        >
          Add Sample Data
        </Button>
        <Button
          variant="link"
          px="2"
          onClick={() => {
            setShowEditOrganizationModal({
              enabled: true,
              organization: edge.node,
            });
          }}
        >
          Edit
        </Button>
        <Button
          variant="link"
          px="2"
          onClick={() => {
            setShowDeleteOrganizationModal({
              enabled: true,
              organization: edge.node,
            });
          }}
        >
          Delete
        </Button>
        <ExportCheckInsButton org={edge.node} />
      </Table.BodyData>
    </Table.BodyRow>
  );
};

export const OrganizationsDashboard = ({ history }: any) => {
  const location = useLocation();
  const feedParams = getFeedParams(location);

  const client = useApolloClient();

  const [showCreateModal, setShowCreateModal] = useState<ModalState>({
    enabled: false,
  });

  const [showEditOrganizationModal, setShowEditOrganizationModal] =
    useState<OrganizationModalState>({
      enabled: false,
    });

  const [showDeleteOrganizationModal, setShowDeleteOrganizationModal] =
    useState<OrganizationModalState>({
      enabled: false,
    });

  const setAccountId = (accountId?: { value: string }) => {
    pushWithHook(
      history,
      getNewLocation(
        {
          ...feedParams,
          page: 1,
          accountId: accountId?.value,
        },
        location
      )
    );
  };

  const setNameQuery = (query: string) => {
    pushWithHook(
      history,
      getNewLocation(
        {
          ...feedParams,
          page: 1,
          query,
        },
        location
      )
    );
  };

  const setCountryId = (countryId?: { value: string }) => {
    pushWithHook(
      history,
      getNewLocation(
        {
          ...feedParams,
          page: 1,
          countryId: countryId?.value,
        },
        location
      )
    );
  };

  const { loading, data, error } = useQuery<GetOrganizations>(
    GET_ORGANIZATIONS,
    {
      variables: {
        ...feedParams,
      },
    }
  );

  return (
    <IndexWrap
      history={history}
      location={location}
      rhsChildren={
        <Button
          variant="brand"
          onClick={() =>
            setShowCreateModal({
              enabled: true,
            })
          }
        >
          Create a new organization
        </Button>
      }
    >
      <Flex flexDirection="column">
        <Flex
          my="2"
          flexDirection="column"
          style={{
            gap: "8px",
          }}
        >
          <TextInput
            onChange={(e) => setNameQuery(e.target.value)}
            value={feedParams.query}
            placeholder="Select by organization name"
            id="name"
            name="name"
          />
          <CountrySelect
            isClearable
            // @ts-ignore
            onChange={setCountryId}
            value={
              feedParams.countryId
                ? {
                    value: feedParams.countryId,
                    label: feedParams.countryId,
                  }
                : null
            }
            placeholder="Select a country"
            id="country"
            name="country"
          />

          <AccountSelect
            placeholder="Select an account"
            // @ts-ignore
            onChange={setAccountId}
            value={
              feedParams.accountId
                ? {
                    value: feedParams.accountId,
                    label: feedParams.accountId,
                  }
                : null
            }
            isClearable
            id="account"
            name="account"
          />

          <Table.Base
            style={{
              marginTop: "16px",
            }}
          >
            <Table.Header>
              <Table.HeaderRow>
                <Table.HeaderData
                  style={{
                    width: "25%",
                  }}
                >
                  Name
                </Table.HeaderData>
                <Table.HeaderData
                  style={{
                    width: "25%",
                  }}
                >
                  Account
                </Table.HeaderData>
                <Table.HeaderData
                  style={{
                    width: "25%",
                  }}
                >
                  Has Exec Product
                </Table.HeaderData>
                <Table.HeaderData
                  style={{
                    width: "25%",
                  }}
                >
                  Has Manager
                </Table.HeaderData>
                <Table.HeaderData
                  style={{
                    width: "25%",
                  }}
                >
                  Country
                </Table.HeaderData>
                <Table.HeaderData
                  style={{
                    width: "25%",
                  }}
                ></Table.HeaderData>
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {!loading &&
                !error &&
                data &&
                data.organizations.edges.map((edge, idx) => (
                  <OrganizationRow
                    setShowEditOrganizationModal={setShowEditOrganizationModal}
                    setShowDeleteOrganizationModal={
                      setShowDeleteOrganizationModal
                    }
                    idx={idx}
                    edge={edge}
                  />
                ))}
            </Table.Body>
          </Table.Base>

          {showCreateModal.enabled && (
            <CreateOrganizationModal
              isOpen={showCreateModal.enabled}
              onClose={() => {
                setShowCreateModal({
                  enabled: false,
                });
              }}
            />
          )}

          {showEditOrganizationModal.enabled &&
            showEditOrganizationModal.organization && (
              <EditOrganizationModal
                isOpen={showEditOrganizationModal.enabled}
                organization={showEditOrganizationModal.organization}
                onClose={() => {
                  setShowEditOrganizationModal({
                    enabled: false,
                  });
                }}
              />
            )}

          {showDeleteOrganizationModal.enabled && (
            <DeleteOrganizationModal
              isOpen={showDeleteOrganizationModal.enabled}
              organization={showDeleteOrganizationModal.organization}
              onClose={() => {
                setShowDeleteOrganizationModal({
                  enabled: false,
                });
              }}
            />
          )}

          <Flex flex="1" justifyContent="flex-end" px={3}>
            {data &&
              data.organizations.pageInfo &&
              feedParams &&
              getNewLocation && (
                <Pagination
                  pageInfo={data.organizations.pageInfo}
                  feedParams={feedParams}
                  getNewLocation={getNewLocation}
                />
              )}
          </Flex>
        </Flex>
      </Flex>
    </IndexWrap>
  );
};

export default OrganizationsDashboard;
