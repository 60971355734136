import { useMutation } from "@apollo/react-hooks";
import { AddressInput, AddressValue, Text, Flex, Select } from "@skodel/sk-ui";
import {
  ModalCloseButton,
  AccountSelect,
  CountrySelect,
  Button,
  Modal,
  Checkbox,
} from "@skodel/sk-ui";
import { FormLabel, TextInput } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { toast } from "react-toastify";

import ORGANIZATION_TYPE_OPTIONS from "../constants/organization-type-options";
import { DropdownValue, OrganizationType } from "../types";
import { CreateOrganizationData, CreateOrganizationVariables } from "./types";

const CREATE_ORGANIZATION = loader("./CreateOrganization.graphql");
const GetAccountsByName = loader("./GetAccountsByName.graphql");

interface CreateOrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface CreateOrganizationFormValues {
  account: DropdownValue | null;
  address: AddressValue | null;
  country: DropdownValue | null;
  hasExecutiveProduct: boolean;
  name: string;
  organizationType: DropdownValue<OrganizationType> | null;
}

const getInitialCreateOrganizationFormValues =
  (): CreateOrganizationFormValues => ({
    account: null,
    address: null,
    country: null,
    hasExecutiveProduct: false,
    name: "",
    organizationType: null,
  });

const ERROR_MESSAGE_TYPENAMES = ["NoSuchObjectError", "ValidationError"];

const CreateOrganizationModal = ({
  isOpen,
  onClose,
  ...otherProps
}: CreateOrganizationModalProps) => {
  const [formState, setFormState] = useState<CreateOrganizationFormValues>(() =>
    getInitialCreateOrganizationFormValues()
  );

  const [createOrganization, state] = useMutation<
    CreateOrganizationData,
    CreateOrganizationVariables
  >(CREATE_ORGANIZATION, {
    onCompleted: (response) => {
      if (response.createOrganization.success) {
        toast.success("Successfully created organization.");

        onClose();
      } else {
        if (
          ERROR_MESSAGE_TYPENAMES.includes(
            response.createOrganization.__typename
          )
        ) {
          toast.error(response.createOrganization.message);
        } else {
          toast.error(
            "Could not create organization. Please check your internet connection and try again."
          );
        }
      }
    },
    onError: () => {
      toast.error(
        "Could not create organization. Please check your internet connection and try again."
      );
    },
    refetchQueries: ["GetOrganizations"],
  });

  const shouldInputBeDisabled =
    formState.name.length === 0 || !formState.country?.value || state.loading;

  const onSubmit = () => {
    if (!formState.country) return;

    createOrganization({
      variables: {
        name: formState.name,
        accountId: formState.account?.value ?? null,
        countryId: formState.country.value,
        ...(formState.address
          ? {
              address: {
                city: formState.address.city,
                country: formState.address.country,
                line1: formState.address.line1,
                state: formState.address.state,
                zip: formState.address.zip,
              },
            }
          : undefined),
        hasExecutiveProduct: formState.hasExecutiveProduct,
        organizationType: formState.organizationType?.value ?? null,
      },
    });
  };

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Create Organization"
    >
      <Modal.Header>
        <Text>Create Organization</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormLabel pt={3} pb={2} htmlFor="name">
          Name
        </FormLabel>
        <TextInput
          id="name"
          name="name"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              name: e.target.value,
            })
          }
          value={formState.name}
        />
        <FormLabel pt={3} pb={2} htmlFor="account">
          Account
        </FormLabel>

        <AccountSelect
          isSearchable
          isClearable
          // @ts-ignore
          onChange={(v: DropdownValue | null) =>
            setFormState({
              ...formState,
              account: v,
            })
          }
          value={formState.account}
          id="account"
          defaultOptions
          name="account"
        />

        <FormLabel pt={3} pb={2} htmlFor="organizationType">
          Organization Type
        </FormLabel>
        <Select
          isClearable
          onChange={(v) =>
            setFormState({
              ...formState,
              organizationType: v || null,
            })
          }
          options={ORGANIZATION_TYPE_OPTIONS}
          value={formState.organizationType}
          id="organizationType"
          name="organizationType"
        />
        <FormLabel pt={3} pb={2} htmlFor="address">
          Address
        </FormLabel>
        <AddressInput
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY as string}
          onChange={(address) => {
            setFormState((prevFormState) => ({
              ...prevFormState,
              address,
            }));
          }}
        />
        <FormLabel pt={3} pb={2} htmlFor="account">
          Country
        </FormLabel>
        <CountrySelect
          isSearchable
          isClearable
          // @ts-ignore
          onChange={(v: DropdownValue) =>
            setFormState({
              ...formState,
              country: v,
            })
          }
          value={formState.country}
          id="country"
          defaultOptions
          name="country"
        />

        <Flex alignItems="center" justifyContent="flex-start" mt={3}>
          <Checkbox
            mr={2}
            checked={formState.hasExecutiveProduct}
            onChange={(newValue: any) => {
              setFormState({
                ...formState,
                hasExecutiveProduct: newValue,
              });
            }}
          />{" "}
          <Text>Has Executive Product</Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={shouldInputBeDisabled} onClick={onSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default CreateOrganizationModal;
